.form-group {
  height: 68px;
  border: 1px solid $borderE2;
  border-radius: 180px;
  @include _1170 {
    height: 52px;
  }
  width: 100%;
  &.form-group__error {
    border: 2px solid $red2;
    & > input {
      color: $red2;
    }
  }
  &__input {
    border-radius: 180px;
    background: $white;
    color: $text;
    padding-left: 39px;
    height: 100%;
    border: none;
    width: 100%;
    @include font(20,30);
    &::placeholder {
      @include font(20,30,null,$inactiveText);

    }
    @include _1170 {
      @include font_btn(18,24);
      &::placeholder {
        @include font_btn(18,24);
      }
      @include _768 {
        @include font_btn(16,20);
        &::placeholder {
          @include font_btn(16,20);
        }
      }
    }
    @include _1170 {
      padding-left: 20px;
    }
  }
  &:first-child {
    margin-top: 0;
  }
}

.form-input {
  height: 68px;
  border: 1px solid $borderE2;
  border-radius: 180px;
  @include _1170 {
    height: 52px;
  }
  width: 100%;

  &__input {
    border-radius: 180px;
    background: $white;
    color: $text;
    padding-left: 39px;
    height: 100%;
    border: none;
    width: 100%;
    @include font(20,30);
    &::placeholder {
      @include font(20,30,null,$inactiveText);

    }
    @include _1170 {
      @include font_btn(18,24);
      &::placeholder {
        @include font_btn(18,24);
      }

    }
    @include _1170 {
      padding-left: 20px;
    }
  }
  &.form-group__error {
    border: 2px solid $red2;
    & > input {
      color: $red2;
    }
  }
}