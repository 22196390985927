.additional {
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include _1440 {
    padding: 60px 0 34px;
  }
  @include _1170 {
    padding: 50px 15px 24px;
  }
  &__description {
    margin-top: 20px;
    text-align: center;
    max-width: 70%;
    @include font(22,33);
    @include _1440 {
      margin-top: 13.5px;
      @include font_btn(16,22);
    }
    @include _979 {
      margin-top: 15px;
      @include font_btn(16,19);
    }
  }
  &-slider {
    width: 100%;
    margin-top: 30px;
    $as: &;
    user-select: none;
    position: relative;
    @include _1170 {
      margin-top: 15px;
    }
    &__title {
      // margin-left: 35px;
      @include font(24,33,700);
      @include _1700 {
        @include font_btn(20,30);
      }
      @include _1600 {
        @include font_btn(16,22);
      }

    }
    &__arrow {
      position: absolute;
      fill: $text;
      height: 18px;
      width: 18px;
      top: 20px;
      transition: fill .2s;
      right: 34px;
      @include _1440 {
        height: 12px;
        width: 12px;
        top: 14px;
        right: 23px;
      }
      @include _1170 {
        right: 10px;
        top: 12px;
      }
    }
    &__img {
      width: calc_fluid(119,179,1280,1920);
      height: calc_fluid(73,110,1280,1920);
      object-fit: cover;
      border-radius: 7.5px;
      @include _1280 {
        width: 119px;
        height: 73px;
      }
      @include _768 {
        width: 84px;
        height: 52px;
      }
    }
    &__nav{
      display: flex;
      justify-content: space-between;
      width: calc(100% + 30px);
      left: -15px;
      position: absolute;
      top: calc(50% - 34px);
      z-index: 5;
    }
    &__nav-btn{
      width: 4%;
      min-width: 36px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #C92323;
      border-radius: 50%;
      border: 1px solid #C92323;
      cursor: pointer;
      transition: all .3s ease;
      &:hover{
        background-color: #fff;
        svg{
          fill: #C92323;
        }
      }
      svg{
        width: 30%;
        aspect-ratio: 1;
        fill: #fff;
        transition: all .3s ease;
      }
      &--prev{
        rotate: 90deg;
      }
      &--next{
        rotate: -90deg;
      }
      &--disable{
        opacity: .6;
      }
    }
    &-slide {
      cursor: pointer;
      position: relative;
      // padding: 14px 100px 14px 14px;
      border: 1px solid $borderE2;
      border-radius: 7.5px;
      display: flex;
      align-items: center;
      transition: border .2s, box-shadow .2s, background .2s;
      // @include _1600 {
      //   padding: 14px 67px 14px 14px;
      // }
      // @include _1170 {
      //   padding: 14px;
      // }
      &:hover {
        border: 1px solid transparent;
        box-shadow: 1.50023px 10.5016px 28.5043px rgba(0, 0, 0, 0.21);
      }
      &.selected {
      //   background: $red2;
      //   #{$as} {
      //     &__title {
      //       color: $white;
      //     }
      //     &__arrow {
      //       fill: $white;
      //     }
      //   }
      }
    }
    &-scrollbar {
      margin-top: 10px;
      height: 6px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $bgD1;
      }
      &__drag {
        height: 100%;
        background: $red2;
      }
    }
  }
  &-selected {
    width: 100%;
    padding: calc_fluid(23,34,1280,1920) calc_fluid(78,116,1280,1920) calc_fluid(23,34,1280,1920) calc_fluid(66,100,1280,1920);
    border: 1px solid $borderE2;
    display: flex;
    margin-top: 30px;
    border-radius: 7.5px;
    @include _1440 {
      margin-top: 20px;
    }
    @include _1280 {
      padding: 23px 78px 23px 66px;
    }
    @include _979 {
      padding: 23px;
    }
    @include _768 {
      flex-direction: column;
      padding: 15px;
    }
    &__img {
      object-fit: cover;
      border-radius: 7.5px;
    }
    &__btn {
      height: 68px;
      width: 295.5px;
      @include font_btn(20,30,700);
      @include _1600 {
        width: 250px;
        height: 52px;
        @include font_btn(16,24);
      }
      @include _1440 {
        width: 197px;
        height: 45px;
        @include font_btn(14,22);
      }
      @include _768 {
        margin-top: 30px;
      }
    }
    &__title {
      @include font(30,36,700);
      margin-top: 20px;
      @include _1600 {
        margin-top: 15px;
        @include font_btn(25,30);
      }
      @include _1440 {
        margin-top: 13px;
        @include font_btn(20,24);
      }
    }
    &__description {
      margin-top: 12px;
      @include font(20,30);
      @include _1600 {
        margin-top: 10px;
        @include font_btn(16,25);
      }
      @include _1440 {
        margin-top: 8px;
        @include font_btn(13,20);
      }
    }
    &-content {
      margin-left: 74px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include _979 {
        margin-left: 30px;
      }
      @include _768 {
        margin: 20px 0 0;
      }
    }
  }
}

.additional-slide{
  padding: clampFluid(30) clampFluid(21);
  display: flex;
  flex-direction: column;
  &__title{
    display: grid;
    grid-template-columns: clampFluid(179) 1fr;
    gap: clampFluid(35);
    align-items: center;
    margin-bottom: clampFluid(16);
    width: 100%;
    img{
      width: 100%;
      height: clampFluid(85);
    }
    p{
      max-width: clampFluid(183);
    }
  }
  &__text{
    span{
      font-family: Source Sans Pro;
      color: #000;
      font-size: clampFluid(16,18);
      line-height: 120%;
    }
  }
}