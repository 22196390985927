.free-voldemort {
  z-index: 1;
  margin-top: 90px;
  width: 100%;
  height: calc_fluid(322,484,1280,1920);
  display: flex;
  justify-content: space-between;
  position: relative;
  @include _1440 {
    margin-top: 60px;
  }
  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__img {
    width: calc_fluid(509,762,1280,1920);
    position: absolute;
    pointer-events: none;
    right: calc_fluid(131,176,1280,1920);
    bottom: calc_fluid(8,0,1280,1920);
    height: calc_fluid(263,414,1280,1920);
    @include _1280 {
      right: calc_fluid(58,131,768,1280);
    }
    object-fit: contain;
    z-index: 2;
  }
  &__poly {
    height: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
    width: 50%;
    display: flex;
    align-items: flex-end;
    top: 0;
    clip-path: polygon(62% 0, 100% 0, 100% 100%, 0% 100%);
    background: $red2;
  }
  &__title {
    font-size: calc_fluid(39,60,1280,1920);
    line-height: calc_fluid(47,60,1280,1920);
    font-weight: 700;
    color: $white;
  }
  &__title2 {
    font-size: calc_fluid(62,93,1280,1920);
    line-height: calc_fluid(50,72,1280,1920);
    font-weight: 700;
    color: $white;
    margin-left: -5px;
  }
  &__description {
    margin-top: calc_fluid(15,32,1280,1920);
    font-size: calc_fluid(14,20,1280,1920);
    line-height: calc_fluid(20,30,1280,1920);
    color: $white;
  }
  &__btn {
    margin-top: calc_fluid(16,25,1280,1920);
    height: calc_fluid(45,68,1280,1920);
    width: calc_fluid(204,307,1280,1920);
    font-weight: 700;
    font-size: calc_fluid(14,20,1280,1920);
    line-height: calc_fluid(22,30,1280,1920);
  }
  &__content {
    width: 50%;
    padding: calc_fluid(43,66,1280,1920);
    padding-left: calc_fluid(15,151,1170,1920);
    @include _1170 {
      padding-left: 15px;
    }
    .voldemort-buttons{
      gap: 20px;
      .a-btn-outline{
        border-color: #fff;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  @include _768 {
    width: 100%;
    height: unset;
    padding: 50px 15px 225px;
    flex-flow: row wrap-reverse;
    &__title {
      @include font_btn(31,37);
    }
    &__title2 {
      @include font_btn(50,57);
    }
    &__description {
      margin-top: 6px;
      @include font_btn(16,19);
    }
    &__btn {
      margin-top: 15px;
      height: 42px;
      width: 290px;
      @include font_btn(13,19);
      @include _480 {
        width: 100%;
      }
    }
    &__poly {
      width: 100%;
      height: 225px;
      bottom: 0;
      top: unset;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
    &__content {
      width: 100%;
      padding: 0;
    }
    &__img {
      bottom: 0;
      right: 0;
      height: calc_fluid(164,260,320,768);
      width: 100%;
    }
  }
}