.catalog {
  &-items {
    margin: 25px -15px -15px;
    display: flex;
    flex-wrap: wrap;
    @include _768 {
      margin: 19.75px -5.25px -5.25px;
    }
  }
  &-mark {
    width: calc(100% / 7 - 30px);
    @include _1800 {
      width: calc(100% / 6 - 30px);
      @include _1600 {
        width: calc(100% / 5 - 30px);
        @include _1170 {
          width: calc(100% / 4 - 30px);
          @include _979 {
            width: calc(100% / 3 - 30px);
            @include _768 {
              width: calc(100% / 3 - 15px);
            }
          }
        }
      }
    }
    border-radius: 7px;
    border: 1px solid $borderE2;
    margin: 15px;
    @include _768 {
      margin: 5.25px;
    }
    &__img {
      width: 100%;
      height: 141px;
      object-fit: contain;
      &--base {
        object-fit: cover;
      }
      @include _1440 {
        height: 130px;
        @include _1170 {
          height: 110px;
          @include _768 {
            height: 80px;
            @include _600 {
              height: 62px;
            }
          }
        }
      }
    }
    &__title {

      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 18px;
      @include font(26,26,700);
      padding: 0 15px 30px;
      width: 100%;
      text-align: center;
      @include _1440 {
        @include font_btn(20,26);
        @include _1170 {
          @include font_btn(18,20);
          padding: 0 15px 20px;
          @include _600 {
            padding: 0 8px 13px;
            margin-top: 8px;
            @include font(11,11);
          }
        }
      }
    }
  }
  &-model {
    border-radius: 7px;
    width: calc(100% / 7 - 30px);
    @include _1800 {
      width: calc(100% / 6 - 30px);
      @include _1600 {
        width: calc(100% / 5 - 30px);
        @include _1170 {
          width: calc(100% / 4 - 30px);
          @include _979 {
            width: calc(100% / 3 - 30px);
            @include _768 {
              width: calc(100% / 3 - 15px);
            }
            @include _600 {
              width: calc(100% / 2 - 15px);
            }
          }
        }
      }
    }
    &.generation {
      width: calc(100% / 3 - 30px);
      @include _1600 {
        width: calc(100% / 2 - 30px);
        @include _768 {
          width: 100%;
        }
      }
    }
    margin: 15px;
    @include _768 {
      margin: 7.5px;
    }
    border: 1px solid $borderE2;
    padding: 25px 5px;
      @include _600 {
        padding: 20px 5px;
      }
    & .catalog-mark__title {
      padding: 0;
      margin: 0;
    }
  }
  &-mark, &-model {
    &:hover {
      border: 1px solid $red2;
      & > .catalog-mark__title {
        color: $red2;
        text-decoration: underline;
      }
    }
  }
  &-more {
    display: flex;
    justify-content: center;
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  &__show-more {
    margin-top: 40px;
    @include _1440 {
      margin-top: 30px;
      @include _1170 {
        margin-top: 20px;
      }
    }
    width: 270px;
    @include _600 {
      width: 100%;
    }
  }
}