.k-scroll {
  &-block {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    user-select: none;
  }
  &-track {
    width: 100%;
    padding-right: 13px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-moz-viewport-scroll {
      display: none;
    }
  }
  &-bar {
    width: 5px;
    margin-top: 5px;
    height: calc(100% - 10px);
    background: #F9F9F9;;
    border-radius: 9px;
    position: relative;
    &.hidden {
      display: none;
    }
    &-slider {
      left: 0;
      top: 0;
      position: absolute;
      width: 5px;
      background: $red2;
      border-radius: 9px;
    }
  }
}