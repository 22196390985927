@mixin font_t($size, $lineHeight){
  font-size: #{$size}px !important;
  line-height: #{$lineHeight}px !important;
  @include _1170 {
    font-size: calc_fluid($size - ($size / 4), $size, 320, 1920) !important;
    line-height: calc_fluid($lineHeight - ($lineHeight / 4), $lineHeight, 320, 1920) !important;
  }
}

.text-form {
  margin: 24px 0 16px !important;
}

.content-text {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px;
  & h1, h2, h3, h4, caption {
    font-weight: bold;
    color: #333;
    width: 100%;
  }
  & p, & span {
    color: $text;
    font-size: calc_fluid(16,20,320,1920);
    line-height: calc_fluid(19,30,320,1920);
  }
  & p {
    margin-bottom: 16px;
    width: 100%;
    /*& + h1, & + h2, & + h3 {
      margin-top: calc_fluid(30,53,320,1920);
    }*/
    & + h1, & + h2, & + h3 {
      margin-top: calc_fluid(30,53,320,1920);
    }  
    & + h2, & + h3 {
      margin-top: 16px;
    }
  }
  & img {
    width: 100% !important;
    object-fit: contain;
  }
  & h1 {
    margin-bottom: 20px;
    font-size: calc_fluid(26,54,320,1920);
    line-height: calc_fluid(30,58,320,1920);
  }
  & h2 {
    font-size: calc_fluid(24,36,320,1920);
    line-height: calc_fluid(28,42,320,1920);
    margin-bottom: 16px;
  }
  & h3 {
    font-size: calc_fluid(18,30,320,1920);
    line-height: calc_fluid(24,36,320,1920);
    margin-bottom: 15px;
  }
  & h4 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }
  & ol, ul {
    margin-bottom: 16px;
    width: 100%;
    & > li {
      width: 100%;
      margin-top: 10px;
      color: $text;
      font-size: calc_fluid(16,20,320,1920);
      line-height: calc_fluid(19,30,320,1920);
      &:first-child {
        margin-top: 0;
      }
    }
  }
  & ol {
    padding-left: 15px;
    & > li {
      list-style-type: decimal;
    }
  }
  & ul {
    padding-left: 12px;
    & > li {
      position: relative;
      list-style-type: none;
      padding-left: 11px;
      &:before {
        left: -9px;
        top: 9px;
        width: 5px;
        height: 5px;
        content: "";
        border-radius: 50%;
        position: absolute;
        background: $red2;
      }
    }
  }
  & table {
    margin: 24px 0 16px;
    width: 100%;
    max-width: 100%;
    @include _768 {
      overflow: scroll;
    }
    & tbody, & thead {
      display: block;
      min-width: 600px;
    }
    & > caption {
      @include font(24,30,700);
      @include _1600 {
        @include font_btn(20,26);
        @include _1170 {
          @include font_btn(18,24);
        }
      }
      margin-bottom: 18px;
      text-align: left;
    }
    & tr {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      &:first-child {
        & td {
          border-top: 2px solid $red;
        }
      }
    }
    & td, & th {
      padding: 20px;
      display: flex;
      vertical-align: middle;
      flex: 1;
      font-weight:normal;
      font-size: calc_fluid(13,20,320,1920);
      line-height: calc_fluid(19,30,320,1920);
      align-items: center;
      margin: -1px 0 0 -1px;
      border: 1px solid #D5E4F3;
    }
  }
  & a {
    color: #EB5757 !important;
    text-decoration: underline;
    &:hover {
      color: #EB5757 !important;
      text-decoration: none;
    }
    &:visited {
      color: #7A33D4 !important;
      text-decoration: underline;
    }
  }
}

.text_underline {
  margin: 24px 0 16px !important;
  border: 1px solid $borderE2;
  border-radius: 8px;
  width: 100%;
  padding: 35px 55px;
  background: $white;
  & > p {
    color: $red2;
  }
}

.file {
  &s {
    margin: 9px -15px 1px;
    display: flex;
    flex-flow: row wrap;
  }
  width: calc(100% / 3 - 30px);
  @include _1170 {
    width: calc(100% / 2 - 30px);
    @include _768 {
      width: calc(100% / 2 - 30px);
      @include _600 {
        width: 100%;
      }
    }
  }
  margin: 15px;
  background: #fff;
  border: 1px solid $borderE2;
  border-radius: 8px;
  padding: 40px;
  @include _1600 {
    padding: 30px;
    @include _1170 {
      padding: 20px;
      @include _768 {
        padding: 15px;
      }
    }
  }
  transition: background-color .3s ease-in-out;
  $f:&;
  &-info {
    margin-top: 25px;
    display: flex;
    &-icon {
      &__svg {
        display: flex;
        width: 23.5px;
        fill: none;
        height: 23.5px;
        margin-right: 15px;
      }
    }
    &__details {
      font-size: 16px;
      line-height: 23px;
      color: $inactiveText;
      transition: color .3s ease-in-out;
    }
  }
  &__title {
    @include font(24,30,700);
    @include _1600 {
      @include font_btn(20,26);
      @include _1170 {
        @include font_btn(18,24);
      }
    }
    transition: color .3s ease-in-out;
  }
  &:hover {
    box-shadow: 1.50023px 10.5016px 28.5043px rgba(0, 0, 0, 0.21);
    border: 1px solid transparent;
  }
}

.gallery {
  margin: 9px -15px 1px;
  display: flex;
  flex-flow: row wrap;
  &-item {
    margin: 15px;
    display: block;
    $gi:&;
    &_row2 {
      width: calc(100% / 2 - 30px);
      @include _600 {
        width: 100%;
      }
      #{$gi}-img {
        height: calc_fluid(250,495,320,1920);
      }
    }
    &_row3 {
      width: calc(100% / 3 - 30px);
      @include _1170 {
        width: calc(100% / 2 - 30px);
        @include _600 {
          width: 100%;
        }
      }
      #{$gi}-img {
        height: calc_fluid(250,312,320,1920);
      }
    }
    &_row4 {
      width: calc(100% / 4 - 30px);
      @include _1320 {
        width: calc(100% / 3 - 30px);
      }
      @include _1170 {
        width: calc(100% / 2 - 30px);
        @include _600 {
          width: 100%;
        }
      }
      #{$gi}-img {
        height: calc_fluid(250,280,320,1920);
      }
    }
    &-img {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-hover {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      width: 100%;
      height: 100%;
      background: rgba(201, 35, 35, 0.7);
      opacity: 0;
      transition: opacity .3s ease-in-out;
      &__zoom {
        height: 40px;
        width: 40px;
        fill: none;
      }
    }
    &:hover {
      #{$gi}-hover {
        opacity: 1;
      }
    }
    &__title {
      margin-top: calc_fluid(12,14,320,1920);
      font-size: calc_fluid(12,14, 320,1920);
      line-height: calc_fluid(18,22,320,1920);
      color: #878787;
    }
  }
}

.quote {
  padding: 30px;
  border-radius: 0 5px 5px 0;
  margin: 24px 0 16px;
  background: #F2F5F6;
  border-left: 4px solid $red2;
  &-description, &-text {
    color: #333;
    font-size: calc_fluid(14,16,320,1920);
    line-height: calc_fluid(21,24,320,1920);
  }
  &-description {
    font-weight: 600;
    margin-top: 10px;
    font-style: italic;
  }
}

.gallery {
  display: flex;
  flex-flow: row wrap;
  margin: 24px -15px 16px;
  & > div {
    margin: 0 15px;
  }
  &__title {
    @include font_t(15, 20);
    color: #333;
    margin-top: 12px;
  }
  &-image {
    width: 100%;
    display: block;
    & > img {
      width: 100%;
      height:100%;
      object-fit: cover;
    }
  }
  $gallery: &;
  &-{
    &w-2 {
      width: calc(50% - 30px);
      #{$gallery}-image {
        max-height:263px;
        height:263px;
      }
    }
    &w-3 {
      width: calc(33% - 30px);
      #{$gallery}-image {
        max-height:171px;
        height:171px;
      }
    }
    &w-4 {
      width: calc(25% - 30px);
    }
    @include _600 {
      &w-2, &w-3, &w-4 {
        width: 100%;
        margin: 24px 15px 16px !important;
        &:first-child {
          margin: 0 15px 16px !important;
        }
      }
    }
  }
}

.faq {
  display: block;
  margin: 24px 0 16px;
  &-line {
    display: block;
    &:not(:first-child) {
      margin-top: 15px;
    }
    &-header {
      display: flex;
      cursor: pointer;
      border: 1px solid $inactiveRed;
      border-radius: 8px;
      position: relative;
      align-items: center;
      user-select: none;
      padding: 30px 40px;
      @include _1170 {
        padding: 15px 20px;
      }
      &-icon {
        position: absolute;
        right: 40px;
        top: calc(50% - 9px);
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include _1170 {
          right: 20px;
        }
        &__svg {
          height: calc_fluid(24,35,320,1920);
          width: calc_fluid(24,35,320,1920);
          transition: transform .3s;
          fill: $text;
        }
      }
      &__title {
        color: $text;
        font-weight: 700;
        font-size: calc_fluid(17,24, 320,1920);
        line-height: calc_fluid(20,33,320,1920);
        padding-right: 25px;
      }
      $fh: &;
      &:hover {
        border: 1px solid $red;
        position: relative;
        z-index: 1;
        #{$fh}__title {
          color: $red;
        }
      }
    }
    &-content {
      display: none;
      padding: 20px 40px;
      @include _1170 {
        padding: 15px 20px;
      }
      font-size: calc_fluid(16,20,320,1920);
      line-height: calc_fluid(20,30,320,1920);
      color: $blk;
    }
    $self: &;
    &.active {
      #{$self} {
        &-header {
          border-radius: 8px 8px 0 0;
          border: 1px solid $inactiveRed;
          border-bottom: none;
          padding-bottom: 0;
          &-icon {
            &__svg {
              transform: rotateX(180deg);
            }
          }
        }
        &-content {
          display: block;
          border: 1px solid $inactiveRed;
          border-radius: 0 0 8px 8px;
          border-top: none;
        }
      }
    }
  }
}