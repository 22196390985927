.seo {
  padding: 80px 0 120px;
  $this: &;
  @include _1170 {
    padding: 50px 15px 60px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @include _979 {
      flex-wrap: wrap;
      justify-content: unset;
      margin-top: 20px;
    }

  }
  &__list{
    display: flex;
    flex-direction: column;
    gap: clampFluid(60);
    margin: clampFluid(40) 0;
  }
  &__item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: clampFluid(40);
    @include _979{
      grid-template-columns: 1fr;
    }
    &:nth-child(2n){
      #{$this}__image{
        order: 2;
      }
    }
  }
  &__img {
    width: 50%;
    border-radius: 7px;
    object-fit: cover;
    @include _1440 {
      width: calc(50% - 45px);
    }
    @include _979 {
      width: 100%;
      height: 400px;
    }
    @include _600 {
      height: 177px;
    }
  }
  &__image{
    width: 100%;
    height: clampFluid(490);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    flex: 1;
    margin-top: 11px;
    margin-left: 53px;
    & .k-scroll-bar {
      margin-left: 3px;
      width: 1px;
      background: $borderE2;
    }
    & .k-scroll-track {
      padding-right: 0;
    }
    & .k-scroll-bar-slider {
      margin-left: -2px;
      border-radius: 0;
      width: 4px;
      background: $inactiveRed;
    }
    @include _1440 {
      margin-left: 35px;
    }
    padding-right: 25px;
    max-height: 473px;
    overflow: hidden;
    @include _979 {
      width: 100%;
      padding-right: 1px;
      margin-top: 20px;
      margin-left: 0;
    }
  }
}