@import "../env";
@import "../mixins";
@import '../components/form-group';
@import '../components/banner';
@import '../components/departments';
@import '../components/additional';
@import '../components/freeVoldemort';
@import '../components/blog';
@import '../components/seo';
@import '../components/catalogItems';
@import '../components/questions';
@import '../components/reviews';
@import '../components/base/text_content';
@import '../variables';
@import '../components/typical_1-filter-block';


.theme-dropdown{
    $this: &;
    &__trigger{
        &:hover{
            & ~ #{$this}__target{
                #{$this}__wrapper{
                    transform: translateY(0);
                }
            }
        }
    }
    &__target{
        overflow: hidden;
    }
    &__wrapper{
        transition: all .3s ease;
        transform: translateY(-100%);
	&:hover {
		transform: translateY(0);
	}
    }
}

.index {
	&-catalog {
		margin-top: 100px;
		@include _1440 {
			margin-top: 60px;
			@include _1170 {
				margin-top: 50px;
			}
		}
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&-advantages {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 100px 0;
		position: relative;
		background: #fff;
		@include _1600 {
			padding: 60px 0;
			@include _1170 {
				padding: 50px 15px;
			}
		}
		&-content {
			//display: flex;
			display: grid;
			margin: 25px -15px -15px;
			//flex-flow: row wrap;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 1fr;
			justify-items: center;

			@include _1600 {
				margin: 15px -10px -10px;
				@include _600 {
					margin: 10px -5px -5px;
				}
			}

			@media(max-width: 1024px) {
				grid-template-columns: repeat(2, 1fr);
			}
			
			@media(max-width: 725px) {
				//grid-template-columns: repeat(1, 1fr);
				//grid-auto-rows: 100px;
				display: flex;
				flex-direction: column; 
			}

		}

		&-item-wrapper {
			position: relative;
		}
		&-item {
			margin: 15px;
			$iai:&;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			cursor: pointer;
			align-items: center;
			border: 1px solid $borderE2;
			border-radius: 5px;
			//width: calc(100% / 3 - 30px);
			width: 95%;
			height: 85%;
			//transition: border .2s, box-shadow .2s;
			padding: calc_fluid(28,42,1280,1920) calc_fluid(21,32,1280,1920);
			position: relative;
			@include _1600 {
				//margin: 10px;
				//width: calc(100% / 2 - 20px);
				@include _600 {
					//width: 100%;
					//margin: 5px;
				}
			}
			@include font(24,30,700);
			@include _1280 {
				padding: calc_fluid(18,28,320,1280) calc_fluid(23,21,320,1280);
				@include font_btn(16,22);
			}
			@media(max-width: 725px) {
				width: 95vw;
				margin: 5px;
				min-height: 85px;
			}

			&__title {
				display: flex;
				width: 100%;
				justify-content: flex-start;
				align-items: center;
			}
			&__icon {
				display: flex;
				height: calc_fluid(29,43,1280,1920);
				width: calc_fluid(74,110,1280,1920);
				min-width: calc_fluid(74,110,1280,1920);
				margin-right: 24px;
				@include _1280 {
					margin-right: 19px;
					height: calc_fluid(16,29,320,1280);
					width: calc_fluid(42,74,320,1280);
					min-width: calc_fluid(42,74,320,1280);
				}
				transition: fill .2s;
				fill: $inactiveRed;
			}
			&.shown {
				
				box-shadow: none !important;

				#{$iai}__description {
					display: flex;
				}
				#{$iai}__icon {
					fill: $red2;
				}
			}
			&__description {
				display: none;
				justify-content: center;
				position: absolute;
				bottom: -1px;
				left: 0;
				transform: translate(0, 100%);
				width: 100%;
				height: auto;
				background: #fff;
				padding: 20px;
				z-index: 2;
				box-shadow: 1.00091px 7.00639px 19.0173px rgba(0, 0, 0, 0.21);
				border-radius: 0px 0px 5px 5px;
				flex-direction: column;
				font-size: 18px;
				font-weight: 400;

				clip-path: inset(0px -30px -30px -30px);
					
					&__order {
						display: flex;
						justify-content: center;
						a {
							margin-top: 15px;
							color: black;
							font-size: 20px;
							text-decoration: underline;
						}
					}

				@media(max-width: 725px){
					position: relative;
					transform: translate(0,0);
					border: none;
					box-shadow: none;
					margin-top: 15px;

					&-text {
						margin-top: 15px;
					}
				

					&:before {
						content: '';
						width: calc(100% + 87px);
						position: relative;
						height: 1px;
						left: -43px;
						background-color: #e2e2e2;
					}
				}
			}


			&:hover, &.shown {
				box-shadow: 1.00091px 7.00639px 19.0173px rgba(0, 0, 0, 0.21);
				border: transparent;
				border-bottom: 1px solid $borderE2;
				border-radius: 5px 5px 0px 0px;
				min-height: auto;
				#{$iai} {
					&__icon {
						fill: $red2;
					}
				
				&__description {
					display: flex;
				}

				}

			}
		}
		&__title {
			font-weight: 900;
			font-size: calc_fluid(28,35,768,1920);
			line-height: calc_fluid(36,38,768,1920);
			@include _768 {
				font-size: calc_fluid(20,28,320,768);
				line-height: calc_fluid(26,36,320,768);
				text-align: center;
			}
			color: $blk;
		}
	}
	&-services {
		margin-top: 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		&__title {
			@include font(48,55,700);
		}
		&__description {
			margin-top: 20px;
			max-width: 1213px;
			& > * {
				@include font(20, 30);
				text-align: center;
			}

		}
		&-items {
			margin: 15px -15px -15px;
			display: flex;
			flex-flow: row wrap;
		}
		&-item {
			width: calc(100% / 2 - 30px);
			padding: 28px 37px 37px;
			margin: 15px;
			border: 1px solid $borderE2;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: border .2s, box-shadow .2s;
			cursor: pointer;
			&:hover {
				border: 1px solid transparent;
				box-shadow: 1.00091px 7.00639px 19.0173px rgba(0, 0, 0, 0.21);
			}

			&__img {
				height: 442px;
				width: 100%;
				object-fit: cover;
			}
			&__title {
				margin-top: 34px;
				@include font(30,36,700,$inactiveRed);
			}
			&__description {
				margin-top: 12px;
				@include font(20,30);
			}
			&__btn {
				height: 68px;
				margin-top: 20px;
				width: 295px;
				@include font_btn(20,30,700);
			}
		}
		@include _1440 {
			margin-top: 66px;
			&__title {
				@include font_btn(32,37);
			}
			&__description {
				margin-top: 13.35px;
				max-width: 809px;
				& > * {
					@include font_btn(14, 20);
					text-align: center;
				}

			}
			&-items {
				margin: 10px -10px -10px;
				display: flex;
			}
			&-item {
				width: calc(100% / 2 - 20px);
				padding: 18px 24px 24px;
				margin: 10px;
				&__img {
					height: 295px;
					width: 100%;
					object-fit: cover;
				}
				&__title {
					margin-top: 22px;
					@include font_btn(20,24);
				}
				&__description {
					margin-top: 8px;
					@include font(14,20);
				}
				&__btn {
					height: 45px;
					margin-top: 22px;
					width: 197px;
					@include font_btn(14,24);
				}
			}
		}
		@include _768 {
			margin-top: 50px;
			&__title {
				@include font_btn(24,28);
			}
			&__description {
				margin-top: 15px;
				& * {
					@include font_btn(13,19)
				}
			}
			&-items {
				margin: 20px 0 -10px;
			}
			&-item {
				margin: 10px 0;
				width: 100%;
				padding: 15px 15px 26px;
				&__img {
					height: 160px;
				}
				&__title {
					margin-top: 19px;
					@include font_btn(18,24);
				}
				&__description {
					@include font_btn(13,19);
				}
				&__btn {
					height: 42px;
					width: 100%;
					margin-top: 20px;
					@include font_btn(13,19);
				}
			}
		}
	}

	&-filter {
		position: relative;
		z-index: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 60px 200px;
		background-color: $red2;
		background-image: url("/static/images/bglogo1.png");
		background-repeat: space;
		border-radius: 45px;
		@include _768 {
			background-size: 100px;
			@include _480 {
				background-size: 51px;
			}
		}
		@include _1440 {
			padding: 40px calc_fluid(40,134,979,1280);
			@include _979 {
				padding: calc_fluid(50,40,320,979) calc_fluid(15,40,320,979);
			}
		}
		&__body {
			display: flex;
			width: 100%;
			flex-flow: row wrap;
			margin: 26px -8.625px -14px;
			@include _1440 {
				margin: 17px -9.5px -9.5px;
				@include _979 {
					margin: 14px 0 -6px;
					justify-content: space-between;
				}
			}
		}
		&__title {
			width: 100%;
			text-align: center;
			@include font(48,55,700,$white);
			@include _1440 {
				@include font_btn(32,37);
				@include _800 {
					@include font_btn(24,28);
				}
			}
		}
		&__btn {
			height: 68px;
			width: 609px;
			@include font_btn(20,33,700);
			margin-top: 27px;
			@include _1440 {
				margin-top: 18px;
				height: 45px;
				width: 406px;
				@include font_btn(14,22);
				@include _800 {
					width: 100%;
				}
			}
		}
		&-field {
			position: relative;
			margin: 14px 8.625px;
			width: calc(100% / 2 - 17.25px);
			border-radius: 180px;
			height: 68px;
			background: $white;
			display: flex;
			padding: 0 50px 0 39px;
			align-items: center;
			&_mini {
				width: calc(100% / 4 - 17.25px);
			}
			&__text {
				@include font(20,30,400,$inactiveText);
				&.--invisible {
					display: none;
				}
			}
			&__text.mark.--search-input {
				outline: none;
				border: none;
				background: none;
		
				margin-left: 0;
		
				&.--invisible {
					display: none;
				}
		
				&::placeholder {
					font-size: calc_fluid(14,16,320,1920);
					line-height: calc_fluid(22,24,320,1920);
				}
			}
			&-arrow {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 78px;
				@include _1600 {
					width: 60px;
					@include _1440 {
						width: 52px;
					}
				}
				//width: calc_fluid(40,58,320,1920);
				display: flex;
				align-items: center;
				justify-content: center;
				&__icon {
					transition: transform .3s, fill .3s;
					width: 20px;
					height: 20px;
					@include _1600 {
						height: 16px;
						width: 16px;
						@include _1440 {
							width: 12px;
							height: 12px;
						}
					}
					//width: calc_fluid(16,20,320,1920);
					//height: calc_fluid(16,20,320,1920);
					fill: $blk;
				}
				@include _1440 {
					&__icon {

					}
				}
			}
			&-children {
				position: absolute;
				user-select: none;
				max-height: 300px;
				//top: calc(100% - 1px);
				//left: -1px;
				//width: calc(100% + 2px);
				top: 100%;
				left: 0;
				width: 100%;
				background: #fff;
				border: 1px solid #CDCDCD;
				display: flex;
				opacity: 0;
				z-index: -1;
				padding: 13px 13px 13px 27px;
				cursor: default;
				&.default {
					display: block;
				}
				&.scroll_h {
					height: 300px;
				}
				&__item {
					padding: 5px 0;
					font-size: 16px;
					line-height: 24px;
					color: $blk;
					cursor: pointer;
					user-select: none;
					transition: color .3s;
					&:hover {
						color: $red;
					}
				}
			}
			&.disabled {
				opacity: .6;
				pointer-events: none;
			}
			$fbi: &;
			&.year {
				display: flex;
				justify-content: space-between;
				padding: 0 0 0 39px;
				@include _600 {
					width: 189px !important;
				}
				#{$fbi} {
					&-arrow {
						width: calc_fluid(63,73,320,1920);
						position: relative;
						&:before {
							content: "";
							position: absolute;
							height: 16px;
							width: 1px;
							top: calc((100% - 16px) / 2);
							left: 2px;
							background: $bgCD;
						}
						&__icon {
							margin-left: -5px;
							width: calc_fluid(22, 33, 320, 1920);
							height: calc_fluid(22, 33, 320, 1920);
							transform: none !important;
							fill: $red2;
						}
					}
				}
			}
			&.active {
				border-radius: 35px 35px 0 0 ;
				#{$fbi} {
					&-arrow {
						&__icon {
							transform: rotate(180deg);
							fill: $red;
						}
					}
					&-children {
						opacity: 1;
						z-index: 1;
					}
				}
			}
			@include _1440 {
				margin: 9.5px 5.75px;
				height: 45px;
				padding: 0 52px 0 26px;
				&.year {
					padding: 0 0 0 26px;
				}
				&__text {
					@include font_btn(14,22,null,$inactiveText)
				}
				@include _979 {
					width: 100%;
					margin: 6px 0;
					padding: 0 52px 0 22px;
					&.year {
						padding: 0 0 0 22px;
					}
					height: 42px;
					&_mini {
						width: calc(100% / 2 - 12.5px);
						@include _480 {
							width: 100%;
						}
					}
				}
			}
		}
	}

}
