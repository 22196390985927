.blog {
  padding-top: 100px;
  @include _1440 {
    padding-top: 70px;
  }
  @include _1170 {
    padding-top: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  &__btn {
    margin-top: 30px;
    width: 295px;
    @include font_btn(20,30,700);
    height: 68px;
    @include _1440 {
      margin-top: 22px;
      height: 45px;
      width: 197px;
      @include font_btn(14,22);
    }
    @include _1170 {
      height: 42px;
      margin-top: 20px;
      @include font_btn(13,19);
    }
    @include _600 {
      width: 260px;
    }
  }
  &__content {
    margin: 15px -15px -15px;
    display: flex;
    flex-wrap: wrap;
    @include _1440 {
      margin: 10px -10px -10px;
    }
  }
  &-item {
    border: 2px solid $borderE2;
    border-radius: 7.5px;
    padding: 35px 35px 25px;
    margin: 15px;
    width: calc(100% / 3 - 30px);
    user-select: none;
    @include _1440 {
      padding: 24px 24px 17px;
      margin: 10px;
      width: calc(100% / 3 - 20px);
    }
    @include _1170 {
      padding: 15px 15px 30px;
    }
    @include _979 {
      width: calc(100% / 2 - 20px);
    }
    @include _600 {
      width: 100%;
      &:not(:first-child) {
        display: none;
      }
    }
    &:last-child {
      display: none;
      @include _979 {
        display: block;
        @include _600 {
          display: none;
        }
      }
    }
    &__img {
      height: 274px;
      width: 100%;
      object-fit: cover;
      border-radius: 4.5px;
      @include _1440 {
        height: 183px;
        border-radius: 3px;
      }
      @include _979 {
        height: 161px;
      }
    }
    &__date {
      margin-top: 22px;
      @include font(24,33,null,$footerBottomText);
      @include _1440 {
        margin-top: 15px;
        @include font_btn(16,22);
      }
      @include _979 {
        margin-top: 23px;
        @include font_btn(13,19);
      }
    }
    &__title {
      margin-top: 20px;
      @include font(30,36,700,$inactiveRed);
      @include _1440 {
        margin-top: 14px;
        @include font_btn(20,24);
      }
      @include _979 {
        margin-top: 10px;
        @include font_btn(18,24);
      }
    }
    &__description {
      margin-top: 24px;
      @include font(20,30);
      @include _1440 {
        margin-top: 15px;
        @include font_btn(14,20);
      }
      @include _979 {
        margin-top: 10px;
        @include font_btn(13,19);
      }
    }
  }
}