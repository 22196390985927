@import "../functions";
@import "../media";

.reviews {
  padding-top: 100px;
  padding-bottom: 90px;
  @include _1440 {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  @include _979 {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  &__wrapper {
    background-image: url("/static/images/bglogo.png");
    background-repeat: space;
    @include _768 {
      background-size: 100px;
      @include _480 {
        background-size: 64px;
      }
    }
    margin-top: 50px;
    @include _1440 {
      margin-top: 40px;
    }
  }
  &-slider {
    margin-top: 46px;
    user-select: none;
    width: 100%;
    @include _1440 {
      margin-top: 30px;
    }
    @include _979 {
      margin-top: 23px;
    }
    &__date {
      @include font(24,33,null,$footerBottomText);
      display: flex;
      align-items: center;
      gap: 10px;
      
      @include _1440 {
        @include font_btn(16,22);
      }
      @include _1170 {
        @include font_btn(13,19);
      }
      span{
        margin-left: auto;
        img{
          width: 25px;
          height: 25px;
          margin-left: 4px;
        }
      }
    }
    &__name {
      margin-top: 14px;
      @include font(24,33,700);
      @include _1440 {
        margin-top: 9px;
        @include font_btn(16,22);
      }
      @include _1170 {
        margin-top: 7px;
        @include font_btn(18,24);
      }
    }
    &__text {
      margin-top: 11px;
      @include font(20,30);
      @include _1440 {
        margin-top: 8px;
        @include font_btn(14,20);
      }
      @include _1170 {
        margin-top: 7px;
        @include font_btn(13,19);
      }
    }
    &__btn {
      margin-top: 31px;
      height: 68px;
      width: 100%;
      @include font_btn(20,30,700);
      @include _1440 {
        margin-top: 20px;
        height: 45px;
        @include font_btn(14,22);
      }
      @include _1170 {
        height: 42px;
        @include font_btn(13,19);
      }
    }
    &-slide {
      padding: 38px 38px 34px;
      background: $white;
      @include _1440 {
        padding: 25px 25px 23px;
      }
      @include _768 {
        padding: 30px 15px;
      }
      height: unset !important;
      border-radius: 7.5px;
      border: 2px solid $borderE2;
    }
    &-scrollbar {
      margin-top: 33px;
      height: 6px;
      @include _1440 {
        margin-top: 23px;
      }
      @include _979 {
        margin-top: 20px;
      }
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $bgD1;
      }
      &__drag {
        height: 100%;
        background: $red2;
      }
    }
  }
}

.reviews{
  &__circle-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clampFluid(20);
    margin-top: clampFluid(40);
    img{
      width: clampFluid(70);
      height: clampFluid(70);
    }
    span{
      font-size: clampFluid(24);
      line-height: 120%;
      color: #333;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  &__widget{
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
}