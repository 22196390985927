.departments {
  padding: calc_fluid(60,80,320,1920) 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  @include _1170 {
    padding: calc_fluid(60,80,320,1920) 15px 30px;
    @include _979 {
      padding: 50px 15px 25px;
    }
  }
  &-map {
    margin-top: 30px;
    height: calc_fluid(523,784,1280,1920);
    width: 100%;
    display: flex;
    @include _1440 {
      margin-top: 20px;
      height: 523px;
      @include _979 {
        height: unset;
        flex-direction: column-reverse;
      }
    }
    $dm: &;
    &-list {
      width: calc_fluid(371,549,1280,1920);
      min-width: calc_fluid(366,549,1280,1920);
      height: 100%;
      &-address {
        cursor: pointer;
        width: 100%;
        &:not(:first-child) {
          margin-top: -4px;
        }
        &__head {
          position: relative;
          border-radius: 7.5px 7.5px 0 0;
          padding: 27px 39px;
          border: 2px solid $borderE2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include _1600 {
            padding: 18.5px 26px;
          }
          @include _979 {
            padding: 18px 20px;
          }
        }
        &__text {
          @include font(24,33,700,$inactiveRed);
          display: flex;
          align-items: center;
          gap: 10px;
          svg{
            width: 20px;
            height: 20px;
            fill: #5A4C66;
          }
          @include _1600 {
            @include font_btn(16,22);
          }
        }
        &__geo {
          position: absolute;
          right: 25px;
          transition: fill .2s;
          top: 39px;
          fill: $inactiveRed;
          width: 24px;
          height: 24px;
          @include _1600 {
            height: 16px;
            width: 16px;
            top: 26px;
            right: 17px;
          }
        }

        &-content {
          margin-top: -2px;
          max-height: 900px;
          overflow: hidden;
          border: 2px solid $borderE2;
          border-radius: 0 0 7.5px 7.5px;
          background: #FAFAFA;
          &__inner {
            padding: 30px 39px;
            @include _1600 {
              padding: 19px 26px;
            }
          }
        }
        &__reviews{
          display: flex;
          gap: 14px;
          @include _480{
            gap: 5px;
          }
          a{
            width: fit-content;
            height: 34px;
            @include _480{
              height: 24px;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        $dla: &;
        &:hover {
          #{$dla} {
            &__geo {
              fill: $red2;
            }
            &__text {
              text-decoration: underline;
            }
          }
        }
        &.collapsed {
          #{$dla} {
            &__head {
              border-radius: 0;
            }
            &__text {
              color: $text;
            }
            &-content {
              max-height: 0;
              border: 2px solid transparent;
            }
          }
        }
      }
      &-phone {
        margin-left: 0;
        color: $inactiveRed;
        &__icon {
          fill: $inactiveRed;
        }
        @include _1600 {
          @include font_btn(18,22);
          &__icon {
            width: 20px;
            height: 20px;
          }
        }
      }
      &__time {
        max-width: 250px;
        margin-top: 25px;
        @include font(23,33);
        @include _1600 {
          margin-top: 16px;
          @include font_btn(15,22);
        }
      }
      &__widget {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include _480{
          flex-direction: column;
          gap: 5px;
        }
        .a-btn-red2{
          height: 50px;
          width: 233px;
        }
      }
      & .k-scroll-bar {
        margin-left: 3px;
        width: 1px;
        background: $borderE2;
      }
      & .k-scroll-track {
        padding-right: 0;
      }
      & .k-scroll-bar-slider {
        margin-left: -2px;
        border-radius: 0;
        width: 4px;
        background: $inactiveRed;
      }
      @include _979 {
        width: 100%;
        max-height: 500px;
        height: unset;
      }
    }
    &__map {
      margin-left: 30px;
      width: 100%;
      height: 100%;
      @include _1440 {
        margin-left: 20px;
        @include _979 {
          height: 256px;
          margin-left: 0;
        }
      }
    }
    &.departments-map-registration {

      @include _979 {
        flex-flow: column;
      }
      #{$dm} {
        &-list {
          max-width: 550px;
          @include _979 {
            max-width: unset;
          }
        }
      }
    }
  }
  &-voldemort {
    margin-top: 38px;
    background: $inactiveRed;
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 45px;
    height: calc_fluid(223,335,1280,1920);
    @include _1700 {
      border-radius: 30px;
      margin-top: 25px;
    }
    @include _1170 {
      height: unset;
    }
    @include _860 {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__poly, &__poly2 {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.1);
    }
    &__poly {
      right: 0;
      top: 0;
      clip-path: polygon(73% 0, 100% 0, 100% 100%, 93% 100%);
    }
    &__poly2 {
      left: 0;
      top: 0;
      clip-path: polygon(0 0, 10% 0, 30% 100%, 0 100%);
    }
    &__img {
      @media(max-width: 1920px){
        margin: 0 30px 0 69px;
      }
      @include _1700 {
        margin: 0 20px 0 46px;
      }
      height: 100%;
      object-fit: cover;
      @include _1170 {
        height: 100%;
        width: 350px;
        @include _860 {
          width: 400px;
          @include _480 {
            width: 100%;
          }
        }
      }
    }
    &__content {
      padding: 42px 62px;
      @include _1700 {
        padding: 27px 42px;
      }
      @include _860 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 46px 15px 15px;
      }
      position: relative;
    }
    &-form {
      display: flex;
      margin: 26px 0 0 -18px;
      @include _1700 {
        margin: 17px 0 0 -12px;
        @include _1170 {
          flex-wrap: wrap;
          @include _860 {
            width: 100%;
            margin: 20px 0 0 -12px;
            @include _480 {
              margin: 8px 0 0 -12px;
            }
          }
        }
      }
    }
    &__title {
      max-width: 500px;
      font-size: calc_fluid(32,48,1280,1920);
      line-height: calc_fluid(37,55,1280,1920);
      font-weight: 700;
      color: #FFFFFF;
      @include _860 {
        text-align: center;
        @include font_btn(24,28);
        @include _480 {
          max-width: 214px;
        }
      }
    }
    &-input {
      width: 280px !important;
      margin-left: 18px;
      &_phone {
        width: 295px;
      }
      @include _1700 {
        width: 186px !important;
        height: 45px !important;
        margin-left: 12px !important;
        &_phone {
          width: 196px !important;
        }
        &__input {
          padding-left: 26px !important;
        }
        &__input, &__input::placeholder {
          font-size: 14px !important;
          line-height: 22px !important;
        }
        @include _1170 {
          width: calc(50% - 12px) !important;
          @include _860 {
            &__input {
              font-size: 13px !important;
              line-height: 19px !important;

            }
            @include _480 {
              width: 100% !important;
              margin-top: 12px;
              height: 42px !important;
            }
          }
        }
      }
    }
    &__btn {
      margin-left: 24px;
      width: 271px !important;
      @include font(20,30,700,$white);
      @include _1700 {
        margin-left: 16px;
        height: 45px;
        width: 181px !important;
        @include font_btn(14,22);
        @include _1170 {
          margin-top: 15px;
          width: 100% !important;
          margin-left: 12px;
          @include _860 {
            margin-top: 17px;
            @include font_btn(13,19);
            @include _480 {
              height: 42px !important;
            }
          }
        }
      }
    }
    &__privacy {
      margin-top: 15px;

      @include font(18,25,null,$white);
      & > a {
        color: $white;
        font-weight: 700;
        text-decoration: underline;
      }
      @include _1600 {
        margin-top: 10px;
      }
      @include _1440 {
        @include font_btn(13,19);
        @include _860 {
          margin-top: 6px;
          text-align: center;
          @include font_btn(12,17);
          @include _480 {
            max-width: 214px;
          }
        }
      }
    }
  }
  &__title {
    @include font(48,55,700);
    & > b {
      color: $text;
    }
    @include _1440 {
      @include font(32,37);
      @include _979 {
        @include font(24,28);
        text-align: center;
      }
    }
  }
  &__description {
    margin-top: 20px;
    @include font(20,30);
    @include _1440 {
      margin-top: 13px;
      @include font(16,20);
      @include _979 {
        @include font(16,19);
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}