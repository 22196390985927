@import "./base/scroll";


.filter-block-typical1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 60px 0;
  @include _1170 {
    padding: 40px 15px;
  }
  &-wrapper {
    padding: 60px 52px;
    margin-top: 40px;

    &.typ2 {
      margin-top: 0;
    }
    @include _1440 {
      margin-top: 0;
    }
    background-color: $red2;
    background-image: url("/static/images/bglogo1.png");
    background-repeat: space;
    border-radius: 45px;
    @include _768 {
      background-size: 100px;
      @include _480 {
        background-size: 51px;
      }
    }
    border: 1px solid transparent;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    @include _979 {
      padding: 30px 15px;
      border-radius: 25px;
    }
    &-items {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      margin-top: 30px;
    }
    @include _1600 {
      width: 1290px;
      &-items {
        flex-flow: row wrap;
        justify-content: space-between;
      }
      @include _1320 {
        width: 1140px;
        @include _1170 {
          width: 100%;
        }
      }
    }
  }
  &-item {
    height: calc_fluid(40,53,320,1920);
    border-radius: 180px;
    background: #fff;
    position: relative;
    border: 1px solid transparent;
    user-select: none;
    $fbi: &;
    display: flex;
    align-items: center;
    margin: 10px 0 0 10px;
    cursor: pointer;
    &-arrow {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__icon {
        width: 46px;
        transition: transform .3s, fill .3s;
        height: calc_fluid(16, 18, 320, 1920);
        fill: $blk;
      }
    }
    &-children {
      position: absolute;
      user-select: none;
      top: calc(100% - 1px);
      left: -1px;
      width: calc(100% + 2px);
      background: #fff;
      border: 1px solid #CDCDCD;
      opacity: 0;
      display: flex;
      z-index: -1;
      padding: 13px 27px;
      cursor: default;
      &.default {
        display: block;
      }
      &.scroll_h {
        height: 300px;
      }
      &__item {
        padding: 5px 0;
        font-size: 16px;
        line-height: 24px;
        color: $blk;
        cursor: pointer;
        user-select: none;
        transition: color .3s;
        &:hover {
          color: $red;
        }
      }
    }
    &__text {
      font-size: calc_fluid(14,16,320,1920);
      line-height: calc_fluid(22,24,320,1920);
      user-select: none;
      padding-left: 39px;
      @include _1170 {
        padding-left: 20px;
      }

      &.--invisible {
        display: none;
      }
    }
    &__text.--search-input {
      outline: none;
      border: none;
      background: none;

      margin-left: 0;

      &.--invisible {
        display: none;
      }

      &::placeholder {
        font-size: calc_fluid(14,16,320,1920);
        line-height: calc_fluid(22,24,320,1920);
      }
    }

    &.glass {
      width: 286px;
      @include _1600 {
        width: 256px;
      }
    }
    &.mark {
      width: 215px;
    }
    &.model {
      width: 195px;
    }
    &.year {
      width: 231px;
      display: flex;
      justify-content: space-between;
      @include _600 {
        width: 189px !important;
      }
      #{$fbi} {
        &-arrow {
          width: 73px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            height: 16px;
            width: 1px;
            top: calc((100% - 16px) / 2);
            left: 2px;
            background: $bgCD;
          }
          &__icon {
            margin-left: -5px;
            width: calc_fluid(22, 33, 320, 1920);
            height: calc_fluid(22, 33, 320, 1920);
            transform: none !important;
            fill: $red2;
          }
        }
      }
    }
    &.body {
      width: 235px;
    }
    &.disabled {
      opacity: .6;
      pointer-events: none;
    }
    @include _1320 {
      width: calc(100% / 2 - 15px) !important;
      margin-left: 0;
      &:nth-child(5) {
        width: 100% !important;
      }
      @include _600 {
        width: 100% !important;
      }
    }
    &.active {
      #{$fbi} {
        &-arrow {
          &__icon {
            transform: rotate(180deg);
            fill: $red;
          }
        }
        &-children {
          opacity: 1;
          z-index: 5;
        }
      }
    }
  }
  &-btn {
    font-weight: 900;
    font-size: calc_fluid(13,16,320,1920);
    line-height: calc_fluid(19,24,320,1920);
    margin: 10px 0 0 29px;
    width: 259px;
    height: calc_fluid(40,53,320,1920);
    @include _1600 {
      width: 100%;
      margin: 16px 0 0 10px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: calc_fluid(20, 48, 320, 1920);
    line-height: calc_fluid(26, 55, 320, 1920);
    text-align: center;
    color: $white;
  }
  &__img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}