.questions {
  background: $red2;
  margin-top: 80px;
  &.last {
    margin-bottom: 40px;
  }
  &__container {
    display: flex;
    @include _1170 {
      justify-content: space-between;
      align-items: center;
    }
    @include _800 {
      flex-direction: column-reverse;
    }
  }
  &__title {
    margin-top: 27px;
    font-weight: 700;
    color: $white;
    font-size: calc_fluid(32,48,1280,1920);
    line-height: calc_fluid(37,55,1280,1920);
    @include _800 {
      text-align: center;
      @include font_btn(24,28);
    }
  }
  &__description {
    margin-top: -2.5px;
    font-size: calc_fluid(24,36,1280,1920);
    color: $white;
    line-height: calc_fluid(34,55,1280,1920);
    @include _800 {
      @include font_btn(16,20);
      margin-top: 5px;
      text-align: center;
    }
  }
  &__car {
    margin-top: calc_fluid(-30,-7,1280,1920);
    margin-left: calc_fluid(-30,-64,1280,1920);
    width: calc_fluid(532,684,1280,1920);
    height: calc_fluid(251,342,1280,1920);
    user-select: none;
    object-fit: cover;
    pointer-events: none;
    @include _1170 {
      margin: 0;
      width: calc_fluid(350,532,800,1280);
    }
    @include _800 {
      margin-top: -50px;
    }
    @include _768 {
      margin-top: -8px;
      width: 290px;
      height: 146px;
    }
  }
  &__content {
    padding-left: 44px;
    @include _1440 {
      padding-left: 12px;
      padding-bottom: 25px;
    }
    @include _1170 {
      flex: 1;
    }
    @include _800 {
      width: 100%;
      padding-left: 0;
    }
    @include _768 {
      padding: 0;
    }
  }
  &__personal {
    margin-top: 14px;
    &, & > a {
      @include font(18,25,null,$white)
    }
    & > a {
      font-weight: 700;
      text-decoration: underline;
    }
    @include _1440 {
      margin-top: 9px;
      &, & > a {
        @include font_btn(13,19);
      }
    }
    @include _1170 {
      text-align: center;
      margin-top: 7px;
      &, & > a {
        @include font_btn(12,17);
      }
    }
  }
  &-form {
    margin-top: 30px;
    display: flex;
    @include _1440 {
      margin-top: 23px;
    }
    @include _1170 {
      flex-wrap: wrap;
    }
    @include _480 {
      margin-top: 20px;
    }
    &-input {
      height: 68px !important;
      width: #{calc_fluid(187,280,1280,1920)} !important;
      @include _1440 {
        height: 45px !important;
      }
      @include _1170 {
        width: calc(100% / 2 - 12px) !important;
      }
      @include _480 {
        width: 100% !important;
      }
      &_phone {
        width: #{calc_fluid(197,295,1280,1920)} !important;

        margin-left: 18px !important;
        @include _1440 {
          margin-left: 12px !important;
        }
        @include _1170 {
          width: 50% !important;
        }
        @include _480 {
          width: 100% !important;
          height: 42px !important;
          margin-top: 12px !important;
          margin-left: 0 !important;
        }
      }
      &__input {
        @include _1440 {
          &, &::placeholder {
            font-size: 14px !important;
            line-height: 22px !important;
          }
          padding-left: 26px !important;
        }
        @include _480 {
          font-size: 13px !important;
          line-height: 19px !important;
          padding-left: 22px !important;
        }

      }
    }
    &__btn {
      margin-left: 30px !important;
      height: 68px !important;
      width: #{calc_fluid(196,294,1280,1920)} !important;
      font-size: 20px !important;
      line-height: 33px !important;
      font-weight: 700 !important;
      @include _1440 {
        margin-left: 20px !important;
        height: 45px !important;
        font-size: 14px !important;
        line-height: 22px !important;
      }
      @include _1170 {
        margin-top: 12px !important;
        margin-left: 0 !important;
        width: 100% !important;
      }
      @include _480 {
        height: 42px !important;
        margin-top: 16px !important;
      }
    }
  }
}