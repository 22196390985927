@import "../mixins";
@import "../media";

.voldemort {
  display: flex;
  flex-flow: row nowrap;
  &.index {
    justify-content: space-between;
  }
  @include _979 {
    margin-top: 20px;
    padding: 20px 15px 0;
    flex-flow: row wrap-reverse;
  }
  &__container {
    display: flex;
  }
  &-content {
    max-width: 677px;
    min-width: 677px;
  }
  &-content, &__img {
    &.index {
      max-width: unset;
      min-width: unset;
      width: 50%;
      object-fit: cover;
      @include _979 {
        width: 100%;
      }
    }
    @include _1700 {
      width: 50%;
      max-width: unset;
      min-width: unset;
    }
    @include _979 {
      width: 100%;
    }
  }
  &__img {
    object-fit: contain;
    border-radius: 3px 0 0 3px;
    @include _979 {
      height: calc_fluid(169,300,320,979);
      border-radius: 3px;
    }
  }
  &-content {
    margin-top: calc_fluid(25,60,1280,1920);
    padding-left: calc_fluid(102,153,1280,1920);
    @include _1280 {
      margin-top: calc_fluid(15,25,320,1280);
    }
    &-title {
      margin-top: 13.7px;
      position: relative;
      max-width: calc_fluid(451,730,1280,1920);
      color: $text;
      font-weight: 700;
      font-size: calc_fluid(36,52,1280,1920);
      line-height: calc_fluid(39,56,1280,1920);
      @include _1280 {
        margin-top: 8px !important;
        font-size: #{calc_fluid(27,36,320,1280)} !important;
        line-height: #{calc_fluid(32,39,320,1280)} !important;
        @include _979 {
          margin-top: 5px !important;
        }
      }
    }
    &__logo {
      height: calc_fluid(38,48.7,1280,1920);
      width: calc_fluid(370,496,1280,1920);
      @include _1280 {
        height: calc_fluid(24,38,320,1280);
        width: calc_fluid(243,370,320,1280);
      }
    }
    &__description {
      max-width: calc_fluid(387,497,1280,1920);
      margin-top: calc_fluid(10,18,1280,1920);
      font-size: calc_fluid(15,18,1280,1920);
      line-height: calc_fluid(22,28,1280,1920);
      @include _1280 {
        margin-top: 10px !important;
        font-size: #{calc_fluid(13,15,320,1280)};
        line-height: #{calc_fluid(19,22,320,1280)};
      }
      & > b {
        text-decoration: underline !important;
      }
      & > a {
        //text-decoration: underline;
        color: $text !important;
      }
    }
    @include _979 {
      padding-left: 0;
      width: 100%;
      &-title {
        max-width: unset !important;
      }
      &__description {
        max-width: unset !important;
      }
    }
  }
  &-buttons {
    display: flex;
    margin-top: calc_fluid(30,45,1280,1920);
    @include _1280 {
      margin-top: #{calc_fluid(20,30,320,1280)} !important;
    }
  }
  &-register, &__callback {
    height: calc_fluid(45,68,1280,1920);
    font-size: calc_fluid(14,20,1280,1920);
    line-height: calc_fluid(22,30,1280,1920);
    @include _1280 {
      height: calc_fluid(42,45,320,1280);
      @include _1170 {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
  &-register {
    width: calc_fluid(181,271.5,1280,1920);
    @include _1280 {
      height: calc_fluid(42,45,320,1280);
      width: calc_fluid(145,181,320,1280);
    }
  }
  &__callback {
    width: calc_fluid(180,270,1280,1920);
    margin-left: calc_fluid(14,22,1280,1920);
    @include _1280 {
      margin-left: calc_fluid(7,14,320,1280);
      width: calc_fluid(138,180,320,1280);
    }
  }
  &-img {
    &-wrapper {
      margin-left: 50px;
      display: flex;
      flex: 1;
      max-height: 529px;
      justify-content: center;
      align-items: center;
    }
  }
  $v:&;
  &.typical {
    margin-top: calc_fluid(-15,-32,320,1920);
    #{$v} {
      &__img {
        height: 100%;
        width: 100%;
      }
      &-img-wrapper {
        height: 529px;
        @include _1440 {
          height: 450px;
          @include _1170 {
            height: 360px;
            @include _979 {
              width: 100%;
              margin-left: 0px;
              @include _600 {
                height: 169px;
              }
            }
          }
        }
      }
      &__container {
        @include _979 {
          flex-wrap: wrap-reverse;
          padding: 0;
        }
      }
      &-content {
        margin-top: 0;
        padding-left: 0;
        @include _1600 {
          width: unset !important;
        }
        &-title {
          margin-top: 32.6px;
          max-width: calc_fluid(451,677,1280,1920);
          color: $text;
          font-size: calc_fluid(36,54,1280,1920);
          line-height: calc_fluid(39,58,1280,1920);
          @include _979 {
            margin-top: 15px !important;
          }
        }
        &__description {
          margin-top: calc_fluid(10,24,1280,1920);
        }
      }
      &-buttons {
        margin-top: calc_fluid(30,36,1280,1920);
      }
    }
  }
}
